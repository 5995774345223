@font-face {
  font-family: 'Kayak';
  src: local('Kayak'),
    url('./resource/fonts/Kayak-Sans-Regular.woff2');
}
@font-face {
  font-family: 'KayakBold';
  src: local('KayakBold'),
    url('./resource/fonts/Kayak-Sans-Bold.woff2');
}
@font-face {
  font-family: 'KayakLight';
  src: local('KayakLight'),
    url('./resource/fonts/Kayak-Sans-Light.woff2');
}

html {

}


* {
  font-family: Kayak, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100dvh;
}

#root {
  margin-bottom: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Feuille de style pour hyapp, mettre ici l'ensemble des donnée */
/* on peut définir des class de style CSS et des ID pour définir les styles */
/* Les classes doivent être affectées aux objets HTML avec l'attribut 'className' à la place de 'class' */
/* Les id fonctionnent également */


.is-main-content {

}

.content-header {
  padding: 12px 15px 0 15px;
}

/* table configuration used in ArrayComponent */
.table {
  border: solid 1px #cccccc;
}
.table th {
  background: #EEE;
}
.sortable:hover {
  background: #cfcfcf;
  cursor: pointer;
}
.sortedHeader {
  background: #000 !important;
  color: #FFF !important;
}
.sortedHeader:hover {
  background: #000 !important;
  color: #d5d5d5 !important;
}
.sortedRow {
  background: #EEE;
}
/************************************/

/* pager buttons for ArrayComponent */
.myPager {
  border: solid 1px #d5d5d5;
  padding: 10px;
  background: #fff;
  color: #000;
}
.myPager:hover {
  border: solid 1px #d5d5d5;
  padding: 10px;
  background: #464646;
  color: #fff;
}
.myPagerDisabled {
  padding: 10px;
  color: #000;
  cursor: not-allowed;

}
.myPagerDisabled:hover {
  cursor: not-allowed;
  color: #000;
}
.myPagerDisabled:active {
  pointer-events: none;
}
/**********************************/

/* form foms */
.label-right {
  text-align: right;
}
.display-value {
  background: #FFF;
  font-family: KayakLight;
  margin-bottom: 10px;
}

/**********************************/

/* generic */
.image-preview {
  max-height: 100px;
  padding: 5px;
}

.image-preview:hover {
  cursor: zoom-in;
}

i {
  margin-right: 10px;
}
.hyappTitle {
  padding: 10px;
  font-family: 'KayakBold';
  font-size: 140%;
}
.hyappSearch {
  padding: 10px;
}

.left{
float:left;
}

.required:after {
  content:" *";
  color: red;
}
/********************************/

.footer {
  padding: 0px;
  width: 100%;
}

#welcomePage {
  padding-top: 3px;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.welcomeTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.welcomeUp {
  display: flex;
  justify-content: center;
}

.welcomeUp img {
  width: 20%;
  height: 20%;
  max-width: 20%;
  object-fit: contain;
}

.appElements {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
  font-size: 125%;
  font-weight: bold;
}
.appElements>* {
  flex: 0 0 50%;
}

.welcomeMiddle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.welcomeTitle {
  text-align: center;
  font-size: 250%;
}
.welcomeSubtitle {
  text-align: center;
  font-size: 150%;
  color: #ffca00;
}
.welcomeText {
  margin-top: 20px;
  text-align: center;
  font-size: 125%;
}
.welcomeBottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}
.welcomeComponent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.welcomeComponent img {
  margin: auto;
  width: 70px;
}

.is-fullheight-with-navbar {
  min-height: calc(100dvh - 52px - 57px);
}

.columns {
  margin: 0;
}
.columns:not(:last-child) {
  margin-bottom: 0;
}
